import React from 'react';
import * as yup from 'yup';
import Skeleton from '@mui/material/Skeleton';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import PersonPinOutlinedIcon from '@mui/icons-material/PersonPinOutlined';
import AutoModeOutlinedIcon from '@mui/icons-material/AutoModeOutlined';
import AllInclusiveOutlinedIcon from '@mui/icons-material/AllInclusiveOutlined';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import { Regex } from '../../../Utils/Common/VIForm/Regex';

export const ABOUT_COMPANY_SCHEMA = yup.object().shape({
  company_introduction: yup
    .string()
    .required('Please enter a brief description of your company')
    .min(100, 'Please enter minimum 100 characters')
    .max(5000, 'Description cannot exceed 5000 characters'),
  linkedin: yup
    .string()
    .optional()
    .test(
      'is-valid-linkedin-url',
      'Invalid LinkedIn company URL format',
      (value) => !value || Regex.linkedinCompany.test(value),
    ),
});

export const POC_INFORMATION_SCHEMA = yup.object().shape({
  job_title_id: yup.mixed().required('Please select a role'),
  custom_job_title: yup.string().when('job_title_id', (role: (string | number)[], schema) => {
    return role?.length && role[0] === OTHER_OPTION_ID
      ? schema.required('Please enter your role')
      : schema;
  }),
  linkedin_url: yup
    .string()
    .required('Please enter your LinkedIn profile URL')
    .matches(Regex.linkedinUser, 'Only personal LinkedIn are accepted'),
  proficient_language_ids: yup
    .array()
    .of(yup.number())
    .required('required')
    .min(1, 'Please select at least one language'),
});

export const COMPANY_SIDE_IMAGE = '/images/Company/onboarding.svg';
export const POC_SIDE_IMAGE = '/images/Company/onboarding-poc.svg';
export const INTEREST_SIDE_IMAGE = '/images/Company/onboarding-interest.svg';

export const COMPANY_SIDE_HEADER = 'Setting up your company profile';
export const POC_SIDE_HEADER = 'About you';
export const INTEREST_SIDE_HEADER = 'Personalizing your experience';

export const CREATE__COMPANY_LOGO_UPLOAD_URL = '/host-company/profile/presign-company-logo';

export const MAX_ALLOWED_LOGO_RESOLUTION = 1600;

export const MAX_ALLOWED_LOGO_SIZE_IN_MB = 2;

export const OTHER_OPTION_ID = 'other';

export const OTHER_JOB_ROLE_OPTION = {
  company_poc_job_title_id: OTHER_OPTION_ID,
  company_poc_job_title: 'Not in the list',
  category: '',
};

export const OTHER_CAREER_FIELD_OPTION = {
  intern_interested_role_id: OTHER_OPTION_ID,
  heading: 'Other',
  description: 'Let us know what other type of interns you’re interested in',
};

// We will store this information as well in backend in future
export const COMPANY_CULTURES_ID_TO_ICON_MAPPING = {
  1: <TrendingUpOutlinedIcon sx={{ fontSize: '32px' }} />,
  2: <TipsAndUpdatesOutlinedIcon sx={{ fontSize: '32px' }} />,
  3: <GroupsOutlinedIcon sx={{ fontSize: '32px' }} />,
  4: <PersonPinOutlinedIcon sx={{ fontSize: '32px' }} />,
  5: <AutoModeOutlinedIcon sx={{ fontSize: '32px' }} />,
  6: <AllInclusiveOutlinedIcon sx={{ fontSize: '32px' }} />,
};

export const WORKPLACE_MODALS_ID_TO_ICON_MAPPING = {
  6: <HomeOutlinedIcon sx={{ fontSize: '32px' }} />,
  7: <HomeWorkOutlinedIcon sx={{ fontSize: '32px' }} />,
  8: <BusinessOutlinedIcon sx={{ fontSize: '32px' }} />,
};

export const DEFAULT_ICON = <AcUnitIcon sx={{ fontSize: '32px' }} />;

export const SkeltonLoader = ({
  numberOfSkeletons = 1,
  width = '100%',
  height = 40,
}: {
  numberOfSkeletons?: number;
  width?: string;
  height?: number;
}) => {
  return new Array(numberOfSkeletons)
    .fill(0)
    .map((_, index) => <Skeleton key={index} width={width} height={height} variant='rounded' />);
};
