import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '../../../Axios/AxiosInterceptor';

const getHiredInternUrl = '/host-company/hired-interns/active-interns';
const getInternshipDetailsUrl = '/host-company/hired-interns/v1/internship-progress/';
const completeInternshipTaskUrl = '/common-services/internship-actions/v1';
const getWeeklyFeedbackDetailsUrl = '/host-company/hired-interns/weekly-feedback-loop/list';
const getOfferDetailsUrl = '/host-company/hired-interns/offer-detail';

const getInternshipDetailsForInternUrl = '/intern-service/v1/internship-progress';
const getInternWeeklyFeedbackDetailsUrl = '/intern-service/weekly-feedback-loop/list';

export const InternshipDetailsApi = createApi({
  reducerPath: 'InternshipDetailsApi',
  baseQuery: customBaseQuery,
  tagTypes: ['internshipDetails', 'hiredInterns'],
  endpoints: (builder) => ({
    getHiredInterns: builder.query({
      query: (body) => {
        return { url: getHiredInternUrl, method: 'POST', body };
      },
      providesTags: ['hiredInterns'],
    }),
    getInternshipDetails: builder.query({
      query: (internshipId) => {
        return {
          url: `${getInternshipDetailsUrl}${internshipId}`,
          method: 'GET',
          body: null,
        };
      },
      providesTags: ['internshipDetails'],
    }),
    completeInternshipTask: builder.mutation({
      query: (body) => {
        return {
          url: completeInternshipTaskUrl,
          method: 'POST',
          body,
        };
      },
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(InternshipDetailsApi.util.invalidateTags(['internshipDetails', 'hiredInterns']));
        } catch (error) {
          console.error('Update failed, tag not invalidated');
        }
      },
    }),
    getWeeklyFeedbackDetails: builder.query({
      query: (body) => {
        return {
          url: getWeeklyFeedbackDetailsUrl,
          method: 'POST',
          body: body,
        };
      },
    }),
    getOfferDetails: builder.query({
      query: (body) => {
        return {
          url: getOfferDetailsUrl,
          method: 'POST',
          body,
        };
      },
    }),
    getInternshipDetailsForIntern: builder.query({
      query: () => {
        return {
          url: getInternshipDetailsForInternUrl,
          method: 'GET',
          body: null,
        };
      },
      providesTags: ['internshipDetails'],
    }),
    getWeeklyFeedbackDetailsForIntern: builder.query({
      query: (body) => {
        return {
          url: getInternWeeklyFeedbackDetailsUrl,
          method: 'POST',
          body: body,
        };
      },
    }),
  }),
});

export const {
  useGetHiredInternsQuery,
  useGetInternshipDetailsQuery,
  useCompleteInternshipTaskMutation,
  useGetWeeklyFeedbackDetailsQuery,
  useGetOfferDetailsQuery,
  useGetInternshipDetailsForInternQuery,
  useGetWeeklyFeedbackDetailsForInternQuery,
  usePrefetch,
} = InternshipDetailsApi;
export default InternshipDetailsApi.reducer;
