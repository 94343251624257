import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '../../../../Axios/AxiosInterceptor';
import { Interviews } from './Interviews';
import { Matches } from './MatchTracker';

export const InternCandidatureApi = createApi({
  reducerPath: 'internCandidatureApi',
  baseQuery: customBaseQuery,
  tagTypes: ['interviews', 'matchTracker', 'offers', 'currentStage', 'hostComapny'],
  endpoints: (builder) => ({
    getInterviewsById: builder.query<Interviews, string>({
      query: (applicationId) => {
        const url = `/intern-service/intern-dashboard/${applicationId}/interviews`;
        return { url, method: 'GET', body: null };
      },
      providesTags: ['interviews'],
    }),
    getInterviewsByCandidatureId: builder.query({
      query: (candidatureId) => {
        const url = `/intern-service/candidature/${candidatureId}/interviews`;
        return { url, method: 'GET', body: null };
      },
      providesTags: ['interviews'],
    }),
    getCompanyDetailsByCandidature: builder.query({
      query: (hcId) => {
        const url = `/intern-service/candidature/hc/${hcId}`;
        return { url, method: 'GET', body: null };
      },
    }),
    rescheduleInterview: builder.mutation({
      query: (body) => {
        const url = 'intern-service/intern-application/internships/interview-rescheduled';
        return { url, method: 'POST', body };
      },
      invalidatesTags: ['interviews', 'currentStage', 'matchTracker'],
    }),
    confirmInterview: builder.mutation({
      query: (body) => {
        const url = 'intern-service/intern-application/internships/interview-scheduled/confirm';
        return { url, method: 'POST', body };
      },
      invalidatesTags: ['interviews', 'matchTracker', 'currentStage'],
    }),
    getOffersById: builder.query({
      query: (applicationId) => {
        const url = `/intern-service/intern-dashboard/${applicationId}/offers`;
        return { url, method: 'GET', body: null };
      },
      providesTags: ['offers'],
    }),
    getInternOfferDetails: builder.query({
      query: (id) => {
        const url = 'intern-service/intern-offer/get/' + id;
        return { url, method: 'GET', body: null };
      },
      providesTags: ['offers'],
    }),
    acceptB2BOffer: builder.mutation({
      query: (body) => {
        const url = 'intern-service/intern-offer/accept-offer-B2B';
        return { url, method: 'POST', body };
      },
      invalidatesTags: ['offers', 'currentStage', 'matchTracker'],
    }),
    getOffersByCandidatureId: builder.query({
      query: (candidatureId) => {
        const url = `/intern-service/candidature/${candidatureId}/offers`;
        return { url, method: 'GET', body: null };
      },
      providesTags: ['offers'],
    }),
    getMatchesById: builder.query<Matches, string>({
      query: (applicationId) => {
        const url = `/intern-service/match/${applicationId}`;
        return { url, method: 'GET', body: null };
      },
      providesTags: ['matchTracker'],
    }),
    getHcByCandidatureID: builder.query({
      query: (canidatureId) => {
        const url = `/intern-service/candidature/${canidatureId}/hc`;
        return { url, method: 'GET', body: null };
      },
      providesTags: ['hostComapny'],
    }),
    getCurrentStageOgApplication: builder.query({
      query: (applicationId) => {
        const url = `/intern-service/intern-dashboard/${applicationId}/current-stage`;
        return { url, method: 'GET', body: null };
      },
      providesTags: ['currentStage'],
    }),
    submitFeedbackResponse: builder.mutation({
      query: (body) => {
        const url = '/intern-service/interview-feedback/create';
        return { url, method: 'POST', body };
      },
      invalidatesTags: ['interviews', 'matchTracker'],
    }),
  }),
});

export const {
  useLazyGetInterviewsByIdQuery,
  useRescheduleInterviewMutation,
  useGetCompanyDetailsByCandidatureQuery,
  useConfirmInterviewMutation,
  useGetInterviewsByCandidatureIdQuery,
  useLazyGetOffersByIdQuery,
  useGetInternOfferDetailsQuery,
  useGetOffersByCandidatureIdQuery,
  useAcceptB2BOfferMutation,
  useLazyGetMatchesByIdQuery,
  useGetHcByCandidatureIDQuery,
  useGetCurrentStageOgApplicationQuery,
  useSubmitFeedbackResponseMutation,
} = InternCandidatureApi;
export default InternCandidatureApi.reducer;
