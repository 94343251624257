import React, { lazy } from 'react';

// Lazy imports
const Dashboard = lazy(() => import('Company/Pages/Dashboard'));
const ProfilePage = lazy(() => import('Company/Pages/Profile'));
const InternshipOpportunityPage = lazy(
  () => import('Company/Pages/InternshipOpportunity/InternshipOpportunityPage'),
);
const InternshipsOpportunityDetailsPage = lazy(
  () => import('Company/Pages/InternshipOpportunityDetails/InternshipsOpportunityDetailsPage'),
);
const InternshipOpportunityCandidatePage = lazy(
  () => import('Company/Pages/InternshipOpportunityCandidate/InternshipOpportunityCandidatePage'),
);
const AdminSettingsPage = lazy(() => import('Company/Pages/Admin/Settings'));
const VITalentPoolPage = lazy(() => import('Company/Pages/VITalentPool'));
const ShortlistCandidatesPage = lazy(
  () => import('Company/Pages/ShortlistCandidates/ShortlistCandidates'),
);

const ManageInternships = lazy(() => import('Company/Pages/ManageInternships'));
const HiredInternPage = lazy(() => import('Company/Pages/HiredInterns/HiredIntern'));
const LoginPage = lazy(() => import('Company/Pages/SignupSignInNew/Login'));
const WaitForInvitation = lazy(() => import('Company/Pages/WaitForInvitation'));
const AccountDeactivated = lazy(() => import('Company/Pages/AccountDeactivated'));
const RegisteredAsIntern = lazy(() => import('Company/Pages/RegisteredAsIntern'));
const RejectedHC = lazy(() => import('Company/Pages/RejectedHC'));

const SignUp = lazy(() => import('Company/Pages/SignupSignInNew/SignUp/SignUp'));
const InvitedUser = lazy(() => import('Company/Pages/SignupSignInNew/InvitedUser/InvitedUser'));
const Onboarding = lazy(() => import('Company/Pages/Onboarding'));

export const privateCompanyRoutesWithHeaderAndNavBar = [
  {
    path: 'profile',
    element: <ProfilePage />,
  },
  {
    path: 'internship-requirement',
    element: <InternshipOpportunityPage />,
  },
  {
    path: 'hiring-management',
    element: <InternshipsOpportunityDetailsPage />,
  },
  {
    path: 'hiring-management/:candidate',
    element: <InternshipOpportunityCandidatePage />,
  },
  {
    oath: 'internship-requirement/:id/:candidate',
    element: <InternshipOpportunityCandidatePage />,
  },
  {
    path: 'settings',
    element: <AdminSettingsPage />,
  },
  {
    path: 'browse-candidates',
    element: <VITalentPoolPage />,
  },
  {
    path: 'shortlisted-candidates',
    element: <ShortlistCandidatesPage />,
  },
  {
    path: 'dashboard',
    element: <Dashboard />,
  },
  {
    path: 'hired-interns',
    element: <HiredInternPage />,
  },
  {
    path: 'hired-interns/:internshipId',
    element: <ManageInternships />,
  },
];

export const onboardingRoutes = [
  {
    path: 'onboarding',
    element: <Onboarding />,
  },
];

export const companySignUpRoutes = [
  {
    path: 'sign-up',
    element: <SignUp />,
  },
  {
    path: 'invited-user',
    element: <InvitedUser />,
  },
];

export const companyBaseRoutes = [
  {
    index: true,
    element: <LoginPage />,
  },
  {
    path: 'get-started',
    element: <LoginPage />,
  },
  {
    path: 'wait-for-invitation',
    element: <WaitForInvitation />,
  },
  {
    path: 'account-deactivated',
    element: <AccountDeactivated />,
  },
  {
    path: 'registered-as-intern',
    element: <RegisteredAsIntern />,
  },
  {
    path: 'in-waiting-list',
    element: <RejectedHC />,
  },
];
