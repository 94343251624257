import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '../../../../Axios/AxiosInterceptor';

const checkDomainBatchMapURL = '/intern-service/v1/check-domain-batch';
const mapUserBatchURL = '/intern-service/map-user-batch/v1';

export const CheckDomainBatchApi = createApi({
  reducerPath: 'checkDomainBatchApi',
  baseQuery: customBaseQuery,
  endpoints: (builder) => ({
    checkDomainBatch: builder.query({
      query: () => ({ url: checkDomainBatchMapURL, method: 'GET' }),
    }),
    mapUserBatch: builder.mutation({
      query: ({ batchCode }) => ({ url: `${mapUserBatchURL}/${batchCode}`, method: 'POST' }),
    }),
  }),
});

export const { useCheckDomainBatchQuery, useMapUserBatchMutation } = CheckDomainBatchApi;

export default CheckDomainBatchApi.reducer;
