import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '../../../../Axios/AxiosInterceptor';

const getUpcomingInterviewsUrl = '/host-company/dashboard/interviews';
const getChecklistUrl = '/host-company/pocs/checklist-items';
const updateChecklistStatusUrl = '/host-company/pocs';
const getInterestedInternsUrl = '/host-company/internship-plan/interested-interns';

export const DashboardApi = createApi({
  reducerPath: 'DashboardApi',
  baseQuery: customBaseQuery,
  tagTypes: ['suggestedInterns'],
  endpoints: (builder) => ({
    getInterestedInterns: builder.query({
      query: (params) => {
        return {
          url: `${getInterestedInternsUrl}?${new URLSearchParams(params).toString()}`,
          method: 'GET',
          body: null,
        };
      },
    }),

    getUpcomingInterviews: builder.query({
      query: (params) => {
        return {
          url: `${getUpcomingInterviewsUrl}?${new URLSearchParams(params).toString()}`,
          method: 'GET',
          body: null,
        };
      },
    }),
    getChecklist: builder.query({
      query: () => {
        return { url: getChecklistUrl, method: 'GET', body: null };
      },
    }),
    updateChecklistStatus: builder.mutation({
      query: (body) => {
        return { url: updateChecklistStatusUrl, method: 'PATCH', body };
      },
    }),
  }),
});

export const {
  useGetInterestedInternsQuery,
  useGetUpcomingInterviewsQuery,
  useGetChecklistQuery,
  useUpdateChecklistStatusMutation,
  usePrefetch,
} = DashboardApi;
export default DashboardApi.reducer;
