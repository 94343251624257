import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { configs } from '../Utils/Common/configs';
import { v4 as uuidv4 } from 'uuid';
import { datadogLogs } from '@datadog/browser-logs';
import { logout } from '../Redux/Services/common';
import { reduxStore } from '../Redux/Store';
import { setTokenDetails } from 'Redux/Slices/Auth/tokenSlice';
import { clearResults } from '../Redux/Slices/Common/Reset/ClearPersist';

const BASE_URL = configs.REACT_APP_BASE_URL;
const UNAUTHORIZED_401 = 401;

const generateRequestId = () => {
  return uuidv4();
};

const logTrigger = (message, jsonAttributes, status, error = null) => {
  datadogLogs.logger.log(message, jsonAttributes, status, error);
};

// Create axios instance
const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: BASE_URL,
});

const axiosLocalesInstance = axios.create({
  baseURL: configs.FE_BASE_URL(),
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const requestId = generateRequestId();
    config.headers['X-Request-ID'] = requestId;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export const handleLogOut = async ({ clearStore }) => {
  sessionStorage.clear();
  localStorage.clear();
  try {
    await logout();
    reduxStore.dispatch(setTokenDetails(null));
    if (clearStore) {
      reduxStore.dispatch(clearResults());
    }
  } catch (error) {
    logTrigger(error?.message, 'User logged out due to invalid token', 'error', error);
  }
};

// Response interceptor
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response?.status === UNAUTHORIZED_401) {
      handleLogOut({ clearStore: true });
    }
    return response;
  },
  (error) => {
    logTrigger(
      error?.message,
      { errorConfig: error?.config, errorCode: error?.code },
      'error',
      error,
    );
    if (error?.response?.status === UNAUTHORIZED_401) {
      handleLogOut({ clearStore: true });
    }
    return Promise.reject(error);
  },
);

export const customBaseQuery = async ({ url, method, body = {}, includeFEBaseUrl = false }) => {
  try {
    let result;
    if (includeFEBaseUrl) {
      result = await axiosLocalesInstance({ url, method, data: body });
    } else result = await axiosInstance({ url, method, data: body });
    return { data: result.data };
  } catch (axiosError) {
    return { error: axiosError };
  }
};
