import React, { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import {
  privateInternRoutesWithHeaderAndNavBar,
  privateInternRoutesWithHeader,
  privateInternRoutes,
  internPublicRoutes,
  internBaseRoutes,
  internSignUpRoutes,
} from './internRoutes';
import {
  privateCompanyRoutesWithHeaderAndNavBar,
  onboardingRoutes,
  companyBaseRoutes,
  companySignUpRoutes,
} from './companyRoutes';

// Layouts
const RootLayout = lazy(() => import('Layout/RootLayout'));
const InternLayout = lazy(() => import('Layout/InternLayout/InternLayout'));
const CompanyLayout = lazy(() => import('Layout/CompanyLayout/CompanyLayout'));

// Route Handlers
const PrivateRouteHandler = lazy(() => import('./PrivateRouteHandler'));
const BaseRouteHandler = lazy(() => import('./BaseRouteHandler'));
const CommonRouteHandler = lazy(() => import('./CommonRouteHandler'));
const SignUpRouteHandler = lazy(() => import('./SignUpRouteHandler'));
const OnboardingRouteHandler = lazy(() => import('./OnboardingRouteHandler'));

// Components
const Welcome = lazy(() => import('Common/Pages/WelcomePage'));
const Notfound = lazy(() => import('Common/Pages/NotFound'));
const ReviewOffer = lazy(() => import('Intern/Components/Dashboard/ReviewOffer'));
const DeclineOffer = lazy(() => import('Intern/Components/Dashboard/DeclineOffer'));
const RequestReceived = lazy(() => import('Intern/Components/Dashboard/RequestReceived'));
const TermsAndConditions = lazy(() => import('Intern/Components/Dashboard/TermsAndConditions'));
const ViewCertificate = lazy(() => import('Intern/Pages/ViewCertificate/ViewCertificate.jsx'));
const Login = lazy(() => import('Intern/Pages/SignupSignin'));
const InternProfile = lazy(() => import('Common/Pages/Profile/Profile'));
const RouterErrorHandler = lazy(() => import('Common/Pages/RouterErrorHandler'));

export const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <RootLayout />,
      errorElement: <RouterErrorHandler />,
      children: [
        {
          element: <BaseRouteHandler />,
          children: [
            {
              index: true,
              element: <Welcome />,
            },
            {
              path: 'welcome',
              element: <Welcome />,
            },
            {
              path: 'intern',
              element: <InternLayout showSidebar={false} showHeader={false} />,
              children: internBaseRoutes,
            },
            {
              path: 'company',
              element: <CompanyLayout showSidebar={false} showHeader={false} />,
              children: companyBaseRoutes,
            },
            {
              path: ':slug',
              element: <Login />,
            },
            { path: ':slug/:batchId', element: <Login /> },
          ],
        },
        {
          element: <PrivateRouteHandler />,
          children: [
            {
              path: 'company',
              element: <CompanyLayout showSidebar={true} showHeader={true} />,
              children: privateCompanyRoutesWithHeaderAndNavBar,
            },
            {
              path: 'intern',
              element: <InternLayout showSidebar={true} showHeader={true} />,
              children: privateInternRoutesWithHeaderAndNavBar,
            },
            {
              path: 'intern',
              element: <InternLayout showSidebar={false} showHeader={true} />,
              children: privateInternRoutesWithHeader,
            },
            {
              path: 'intern',
              element: <InternLayout showSidebar={false} showHeader={false} />,
              children: privateInternRoutes,
            },

            {
              path: '/review-offer',
              element: <ReviewOffer />,
            },
            {
              path: '/decline-offer',
              element: <DeclineOffer />,
            },
            {
              path: '/request-received',
              element: <RequestReceived />,
            },
          ],
        },
        {
          element: <CommonRouteHandler />,
          children: [
            {
              path: 'intern-profile/:id',
              element: <InternProfile />,
            },
          ],
        },
        {
          element: <SignUpRouteHandler />,
          children: [
            {
              path: 'company',
              children: companySignUpRoutes,
            },
            {
              path: 'intern',
              children: internSignUpRoutes,
            },
          ],
        },
        {
          element: <OnboardingRouteHandler />,
          children: [
            {
              path: 'company',
              element: <CompanyLayout showSidebar={false} showHeader={false} />,
              children: onboardingRoutes,
            },
          ],
        },
        {
          path: 'intern',
          children: internPublicRoutes,
        },

        {
          path: '/term-condition',
          element: <TermsAndConditions />,
        },
        {
          path: '/employable/:uuid',
          element: <ViewCertificate />,
        },
        {
          path: 'notfound',
          element: <Notfound />,
        },
        {
          path: '*',
          element: <Notfound />,
        },
      ],
    },
  ],
  {
    future: {
      v7_relativeSplatPath: true, // Enables relative paths in nested routes
      v7_fetcherPersist: true, // Retains fetcher state during navigation
      v7_normalizeFormMethod: true, // Normalizes form methods (e.g., POST or GET)
      v7_partialHydration: true, // Supports partial hydration for server-side rendering
      v7_skipActionErrorRevalidation: true, // Prevents revalidation when action errors occur
    },
  },
);
