import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '../../../Axios/AxiosInterceptor';
import {
  COMPANY_CULTURES_ID_TO_ICON_MAPPING,
  DEFAULT_ICON,
  WORKPLACE_MODALS_ID_TO_ICON_MAPPING,
} from 'Company/Pages/Onboarding/constants';

const getCompanyDetails = '/host-company/get-basic-details';
const savePhotosUrl = '/host-company/profile/save-images';
const saveVideosUrl = '/host-company/profile/save-videos';
const getAdminDetailsUrl = '/host-company/admin-setting/user/profile';
const updateCompanyProfilePicApiUrl = '/host-company/profile/company-logo';
const updateCompanyInfoApiUrl = '/host-company/companies';
const updatePocInfoApiUrl = '/host-company/pocs';
const uploadFileS3URL = '/host-company/s3/upload-file';

const startOnboardingApiUrl = '/host-company/pocs/start-onboarding';
const completeOnboardingApiUrl = '/host-company/pocs/complete-onboarding';

const getCompanyCultureApiUrl = '/common-services/company-cultures';
const getFundingOptionsUrl = '/common-services/funding-type';
const getAnnualRevenueOptionsUrl = '/common-services/annual-revenue';
const getWorkplaceModalsApiUrl = '/common-services/flexible-work-environment';
const getCompanyToolsApiUrl = '/common-services/company-tools';
const commonProfileUrl = '/host-company/profile/v1';

const getPocHiringReasonsApiUrl = '/common-services/poc-hiring-reasons';
const getPocJobTitlesApiUrl = '/common-services/poc-job-titles';
const getLanguageListApiUrl = 'common-services/language/list';
const getInternInterestedRoles = '/common-services/intern-interested-roles';

export const OnboardingApi = createApi({
  reducerPath: 'OnboardingApi',
  baseQuery: customBaseQuery,
  tagTypes: [
    'company-details',
    'company-tools',
    'poc-job-titles',
    'interested-roles',
    'company-profile-details',
  ],
  endpoints: (builder) => ({
    getCompanyDetails: builder.query({
      query: () => {
        return { url: getCompanyDetails, method: 'GET', body: null };
      },
      providesTags: ['company-details'],
    }),
    getCompanyProfileDetails: builder.query({
      query: () => {
        return {
          url: '/host-company/profile/v1/company-profile',
          method: 'GET',
          body: null,
        };
      },
      providesTags: ['company-profile-details'],
      transformResponse: (response) => {
        return response?.data?.payload;
      },
    }),
    saveImages: builder.mutation({
      query: ({ mediaList }) => {
        return {
          url: savePhotosUrl,
          method: 'POST',
          body: mediaList,
        };
      },
      invalidatesTags: ['company-profile-details'],
    }),
    saveVideos: builder.mutation({
      query: ({ mediaList }) => {
        return {
          url: saveVideosUrl,
          method: 'POST',
          body: mediaList,
        };
      },
      invalidatesTags: ['company-profile-details'],
    }),
    uploadS3Files: builder.mutation({
      query: ({ data }) => {
        return {
          url: uploadFileS3URL,
          body: data,
          method: 'POST',
        };
      },
    }),
    getSignedInUserDetails: builder.query({
      query: () => {
        return {
          url: getAdminDetailsUrl,
          body: null,
          method: 'GET',
        };
      },
      transformResponse: (response) => {
        return response?.data?.payload;
      },
    }),
    updateCompanyProfilePic: builder.mutation({
      query: (body) => {
        const url = updateCompanyProfilePicApiUrl;
        return { url, method: 'PUT', body };
      },
      invalidatesTags: ['company-details', 'company-profile-details'],
    }),
    updateCompanyProfile: builder.mutation({
      query: ({ payload }) => ({
        url: `${commonProfileUrl}/profile`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['company-profile-details'],
    }),
    editCompanyIntroduction: builder.mutation({
      query: ({ payload }) => ({
        url: `${commonProfileUrl}/introduction`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['company-profile-details'],
    }),
    editCompanySocialMedia: builder.mutation({
      query: ({ payload }) => ({
        url: `${commonProfileUrl}/social-media`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['company-profile-details'],
    }),
    editCompanyArrangements: builder.mutation({
      query: ({ payload }) => ({
        url: `${commonProfileUrl}/company-arrangements`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['company-profile-details'],
    }),
    editCompanyTools: builder.mutation({
      query: ({ payload }) => ({
        url: `${commonProfileUrl}/company-tools`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['company-profile-details', 'company-tools'],
    }),
    updateCompanyInfo: builder.mutation({
      query: (body) => {
        const url = updateCompanyInfoApiUrl;
        return { url, method: 'PATCH', body };
      },
      invalidatesTags: ['company-details', 'company-tools'],
    }),
    updatePocInfo: builder.mutation({
      query: (body) => {
        const url = updatePocInfoApiUrl;
        return { url, method: 'PATCH', body };
      },
      invalidatesTags: ['company-details', 'poc-job-titles', 'interested-roles'],
    }),
    startOnboarding: builder.mutation({
      query: (body) => {
        const url = startOnboardingApiUrl;
        return { url, method: 'POST', body };
      },
    }),
    completeOnboarding: builder.mutation({
      query: (body) => {
        const url = completeOnboardingApiUrl;
        return { url, method: 'POST', body };
      },
    }),
    getCompanyCulture: builder.query({
      query: () => {
        return { url: getCompanyCultureApiUrl, method: 'GET', body: null };
      },
      transformResponse: (response) => {
        return response?.data?.payload?.map((item) => ({
          heading: item.company_culture_name,
          description: item.company_culture_description,
          icon: COMPANY_CULTURES_ID_TO_ICON_MAPPING[item.company_culture_id] || DEFAULT_ICON,
          company_culture_id: item.company_culture_id,
        }));
      },
    }),
    getWorkplaceModals: builder.query({
      query: () => {
        return { url: getWorkplaceModalsApiUrl, method: 'GET', body: null };
      },
      transformResponse: (response) => {
        return response?.data?.payload?.map((item) => ({
          heading: item.flexible_work_environment_name,
          description: item.flexible_work_environment_description,
          icon:
            WORKPLACE_MODALS_ID_TO_ICON_MAPPING[item.flexible_work_environment_id] || DEFAULT_ICON,
          flexible_work_environment_id: item.flexible_work_environment_id,
        }));
      },
    }),
    getFundingOptions: builder.query({
      query: () => ({
        url: getFundingOptionsUrl,
        method: 'GET',
        body: null,
      }),
      transformResponse: (response) => {
        return response?.data?.payload?.results;
      },
    }),
    getAnnualRevenueOptions: builder.query({
      query: () => ({
        url: getAnnualRevenueOptionsUrl,
        method: 'GET',
        body: null,
      }),
      transformResponse: (response) => {
        return response?.data?.payload;
      },
    }),
    getCompanyTools: builder.query({
      query: () => {
        return { url: getCompanyToolsApiUrl, method: 'GET', body: null };
      },
      providesTags: ['company-tools'],
      transformResponse: (response) => {
        return response?.data?.payload || { category_tools: [], custom_tools: [] };
      },
    }),
    getPocHiringReasons: builder.query({
      query: () => {
        return { url: getPocHiringReasonsApiUrl, method: 'GET', body: null };
      },
      transformResponse: (response) => {
        return response?.data?.payload
          ?.map((item) => ({
            value: item.poc_hiring_reason_id,
            label: item.poc_hiring_reason,
          }))
          ?.filter((item) => !item.is_archived);
      },
    }),
    getPocJobTitles: builder.query({
      query: () => {
        return { url: getPocJobTitlesApiUrl, method: 'GET', body: null };
      },
      transformResponse: (response) => {
        return response?.data?.payload || { category_job_titles: [], custom_job_titles: [] };
      },
      providesTags: ['poc-job-titles'],
    }),
    getLanguageList: builder.query({
      query: () => {
        return { url: getLanguageListApiUrl, method: 'POST', body: null };
      },
      transformResponse: (response) => {
        return response?.data?.payload || [];
      },
    }),
    getInternInterestedRoles: builder.query({
      query: () => {
        return { url: getInternInterestedRoles, method: 'GET', body: null };
      },
      providesTags: ['interested-roles'],
      transformResponse: (response) => {
        return (response?.data?.payload || [])?.map((item) => ({
          heading: item.intern_interested_role_name,
          description: item.intern_interested_role_description,
          intern_interested_role_id: item.intern_interested_role_id,
        }));
      },
    }),
  }),
});
export const {
  useGetCompanyDetailsQuery,
  useUpdateCompanyProfilePicMutation,
  useGetCompanyProfileDetailsQuery,
  useEditCompanyArrangementsMutation,
  useEditCompanyIntroductionMutation,
  useEditCompanySocialMediaMutation,
  useEditCompanyToolsMutation,
  useUploadS3FilesMutation,
  useUpdateCompanyProfileMutation,
  useSaveVideosMutation,
  useSaveImagesMutation,
  useGetSignedInUserDetailsQuery,
  useGetAnnualRevenueOptionsQuery,
  useGetFundingOptionsQuery,
  useUpdateCompanyInfoMutation,
  useUpdatePocInfoMutation,
  useStartOnboardingMutation,
  useCompleteOnboardingMutation,
  useGetCompanyCultureQuery,
  useGetWorkplaceModalsQuery,
  useGetCompanyToolsQuery,
  useGetPocHiringReasonsQuery,
  useGetPocJobTitlesQuery,
  useGetLanguageListQuery,
  useGetInternInterestedRolesQuery,
  usePrefetch,
} = OnboardingApi;
export default OnboardingApi.reducer;
