import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '../../../../Axios/AxiosInterceptor';

const CheckSlugURL = 'common-services/batch/check-slug?slug=';
const getBatchDetailsByBatchCodeURL = '/common-services/batch/validate/v1';

export const CheckSlugApi = createApi({
  reducerPath: 'checkSlugApi',
  baseQuery: customBaseQuery,
  tagTypes: ['checkSlugType'],
  endpoints: (builder) => ({
    checkSlug: builder.query({
      query: (body) => ({ url: `${CheckSlugURL}${body?.slugVal}`, method: 'GET' }),
    }),
    getBatchDetailsByBatchCode: builder.query({
      query: ({ batchCode, status }) => ({
        url: status
          ? `${getBatchDetailsByBatchCodeURL}/${batchCode}?status=${status}`
          : `${getBatchDetailsByBatchCodeURL}/${batchCode}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useCheckSlugQuery, useLazyGetBatchDetailsByBatchCodeQuery } = CheckSlugApi;

export default CheckSlugApi.reducer;
