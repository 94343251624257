/* eslint-disable no-useless-escape */
export const Regex = {
  name: /^[a-zA-Z]([.]?[a-zA-Z]?)*( [a-zA-Z]?([.]?[a-zA-Z]?)*)*$/,
  companyName: /^([a-zA-Z0-9])([a-zA-Z0-9\s\.\,\/\&\-\(\)']?)*$/,
  year: /^\d{4}$/,
  alphaNumericWithSpace: /^[a-zA-Z0-9 ]*$/,
  linkedinCompany: /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/company\/([-a-zA-Z0-9-]+)\/*/m,
  linkedinUser: /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/in\/([-a-zA-Z0-9-]+)\/*/m,
  linkedin: /^https:\/\/[a-z]{2,3}\.linkedin\.com\/.*$/m,
  facebook:
    /^(http\:\/\/|https\:\/\/)?(?:www\.)?facebook\.com\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-\.]*\/)*([\w\-\.]*)/,
  twitter: /^(?:https?:\/\/)?(?:www\.)?(twitter\.com|x\.com)\/(\w{2,15})\/?(?:\?\S+)?(?:\#\S+)?$/m,
  youtube:
    /^https:\/\/(www\.)?youtube\.com\/(@?[a-zA-Z0-9_-]+|c\/[a-zA-Z0-9_-]+|channel\/[a-zA-Z0-9_-]+|user\/[a-zA-Z0-9_-]+)\/?$/,
  tiktok:
    /^(?:http(?:s)?:\/\/)?(?:(?:www)\.(?:tiktok\.com)(?:\/)(?!foryou)(@[a-zA-z0-9]+)(?:\/)(?:video)(?:\/)([\d]+)|(?:m)\.(?:tiktok\.com)(?:\/)(?!foryou)(?:v)(?:\/)?(?=([\d]+)\.html))/m,
  instagram: /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)\/(\w+)/m,
  github: /^https:\/\/(www\.)?github\.com\/[a-zA-Z0-9_-]+\/?$/,
  crunchbase: /^https:\/\/(www\.)?crunchbase\.com\/organization\/[a-zA-Z0-9_-]+\/?$/,
  glassdoor:
    /^https:\/\/www\.glassdoor\.(?:co\.uk|com)\/Overview\/Working-at-([a-zA-Z0-9-]+)-EI_IE\d+\.\d+,\d+\.htm$/,
  wellfound: /^https:\/\/wellfound\.com\/company\/([a-zA-Z0-9_-]+)$/,
  medium: /^https:\/\/(www\.)?medium\.com\/@[a-zA-Z0-9_-]+\/?$/,

  valid_email:
    /^[a-zA-Z0-9_](?!.*\.\.)(?:[a-zA-Z0-9._+-]*[a-zA-Z0-9_-])?@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/,
  valid_url:
    /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,100}(\/[a-zA-Z0-9%._~:\/?#\[\]@!$&'()*+,;=-]*)?$/,
};
export const SPECIAL_CHAR_REGEX = /[^\w\d\s-]/g;
export const SPECIAL_CHAR_REGEX_WITHOUT_PERCENT = /[^%\w\d\s-]/g;
export const ATLEAST_ONE_ALPHABET_REGEX = /.*[a-zA-Z] .*/;
export const MULTIPLE_SPACE_REGEX = /\s /g;
